.mainContainer {
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 600px;
    min-height: 310px;
    margin: 30px auto;
}
.footer {
    text-align: center;
    font-size: 18px;
}